import { SlideCanvas } from "./canvas/canvas";
import * as slideTemplates from "./slideTemplates/slideTemplates";
import elementManager from "./elements/elementManager";

export { SlideCanvas, slideTemplates, elementManager };

// DEV-START
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(err => {
        // eslint-disable-next-line no-console
        console.error(err);
    });
}
// DEV-END
