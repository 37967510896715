import { getLogger, LogGroup } from "js/core/logger";
import { detectTextContent } from "js/core/services/sharedModelManager";
import * as geom from "js/core/utilities/geom";
import { BlockStructureType } from "legacy-common/constants";
import { getStaticUrl } from "legacy-js/config";
import { SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import React from "react";

import { BaseElement } from "../base/BaseElement";
import { SVGElement } from "../base/SVGElement";
import { TextElement } from "../base/Text/TextElement";

const logger = getLogger(LogGroup.ELEMENTS);

class SlidePlaceholderBackground extends SVGElement {
    get backgroundImageUrl() {
        return getStaticUrl("/images/placeholderbg.svg");
    }

    _load() {
        return new Promise(resolve => {
            const image = new Image();
            image.onload = () => {
                this.imageSize = new geom.Size(image.naturalWidth, image.naturalHeight);
                resolve();
            };
            image.src = this.backgroundImageUrl;
        });
    }

    renderSVG(props, styles) {
        return (<SVGGroup ref={this.ref} key={this.id}>
            <image
                x={-50}
                y={-50}
                href={this.backgroundImageUrl}
                onLoad={this.getImageOnLoadPromiseResolver(this.backgroundImageUrl)}
            />
        </SVGGroup>);
    }
}

class SlidePlaceholder extends BaseElement {
    getCanvasMargins() {
        return {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
        };
    }

    _build() {
        this.canvas.$el.addClass("slide_placeholder_canvas");

        this.background = this.addElement("background", () => SlidePlaceholderBackground, {
            preventExport: true
        });

        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.SINGLE_BLOCK,
            placeholder: "Add a note about this slide",
            scaleTextToFit: true,
            allowStyling: false,
            allowNewLines: true
        });
    }

    _calcProps(props, options) {
        const { size, children } = props;

        const backgroundProps = this.background.calcProps(size);
        backgroundProps.bounds = new geom.Rect(0, 0, size);

        const textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(0, 0, size);

        return { size };
    }

    _exportToSharedModel() {
        return this.text._exportToSharedModel();
    }

    _importFromSharedModel(model) {
        const textContent = detectTextContent(model);
        if (!textContent?.length) return;

        const textContentMerged = textContent.reduce((acc, { mainText, secondaryTexts }) => ({
            mainText: acc.mainText ? acc.mainText : mainText,
            secondaryTexts: [...acc.secondaryTexts, ...(acc.mainText ? [mainText] : []), ...secondaryTexts]
        }), { mainText: null, secondaryTexts: [] });

        return this.text._importFromSharedModel({ ...model, textContent: [textContentMerged] });
    }

    async finishWithAi() {
        // noop
    }
}

export const elements = {
    SlidePlaceholder,
};
